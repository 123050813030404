/* .sticky-nav {
  float: top;
} */

/* .sticky-nav {
  position: sticky;
  top: 0;
} */

#header {
  /* position: fixed; */
  /* position: sticky; */
  /* 'sticky' causes components below navbar to be scrollable up under navbar */
  top:0;
  padding:4px;
  background: lightblue;

  width: 100%;
  z-index: 10;
}

#content {
  height: 90px;
  position: relative;
  z-index:1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapSkuChooseType {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.stdError {
  border: 2px solid red;
  padding: 7px 7px 0;
  margin-top: 10px;
  margin-bottom: 1px;
  background-color: #f0f0f0;
}

.successMessage {
  border: 2px solid green;
  padding: 7px 7px 0;
  margin-top: 10px;
  margin-bottom: 1px;
  background-color: #f0f0f0;
}

.dismissMessageButton {
  margin-top: 10px;
  margin-bottom: 20px;
}

.backButton {
  padding: 7px 7px 0;
  margin-top: 20px;
  margin-bottom: 10px;

  margin-left: 300px;
  /* TODO: surely there is a better way to make this hug the right side */
  /* background-color: green;
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px; */
}

.text-black {
  color: black;
}

.btn-just-got-clear {
  background-color: aqua;
}

.react-player {
  height: auto !important;
  aspect-ratio: 16/9;
  border: solid black;
}

.oneline-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.oneline-container-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.oneline-container-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

body {
  margin: 0;
  margin-top:114px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(242, 202, 179, 0.48) !important;
}
