button,
input,
optgroup,
select,
textarea {
  padding-left: 0.3em;
  padding-right: 0.3em;
  border: solid black;
  border-width: 1px;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; /* 1 */

  background-color: #eaeaea;
  /* background-color: transparent; */
  /* 2 */

  background-image: none; /* 2 */
}

button:hover {
  background-color: #faf6f6;
  transition: 0.6s;
}

button:active {
  background-color: #bfb6b6;
  transition: 0.6s;
}

.container-headline {
  display: flex;
  justify-content: center
}

.container-hug-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.container-hug-bottom-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.container-left {
  display: flex;
}

.container-input-label {
  display: flex;
  justify-content: flex-end;
  /* text-align: right; */
  /* margin-left: auto; */
  /* justify-content: center; */
  /* align-items: center; */
  /* align-items: right; */
  /* vertical-align: top; */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:block;
  width : 90px;
}
