.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.homeBkgrnd, .signInBkgrnd {
  background-image: url(https://images.unsplash.com/photo-1477505982272-ead89926a577?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80);
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.circle {
  border-radius: 50%;
  width: 170px;
  height: 170px;
}
.blue_circle {
  background: lightblue !important;
}
.dark_creme_circle {
  background: #8C452B !important;
}
.light_creme_circle {
  background: #F2CAB3!important;
}

h1.white, h3.white {
  color: white;
}

#duotone_sign_in {
  background: url('https://images.unsplash.com/photo-1578916171728-46686eac8d58?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
}

#duotone_sign_in::before {
  background-color: rgb(24 178 252);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;
  position: absolute;
  top: 0;
  left: 0;
}

#duotone_sign_in::after {
  background-color: rgb(121 146 237);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  position: absolute;
  top: 0;
  left: 0;
}

#duotone_sign_up {
  background: url('https://images.unsplash.com/photo-1578916171728-46686eac8d58?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
}

#duotone_sign_up::before {
  background-color: rgb(24 178 252);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;
  position: absolute;
  top: 0;
  left: 0;
}

#duotone_sign_up::after {
  background-color: rgb(121 146 237);
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up)  */
@media (min-width: 768px) {
  .center-message {
    max-width: 100%;
    margin-top: -15% !important;
    margin-top: -5% !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .center-message {
    max-width: 100%;
    margin-top: -10% !important;
    margin-top: -5% !important;
  }
  #js-Footer {
    width: 768px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .center-message {
    max-width: 100%;
    margin-top: -10% !important;
    margin-top: -5% !important;
  }
  #js-Footer {
    width: 940px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .center-message {
    max-width: 100%;
    margin-top: -5% !important;
  }
  #js-Footer {
    width: 940px;
  }
} 

